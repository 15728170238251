import { path, reduce, and, map, isEmpty, last, head } from 'ramda'
import { selectTheme } from './ui'
import { AppStateType } from '../types'
import { CustomerType } from '../types/customer'
import { ContentDataType } from '../types/api'
import { OrderType, OrderHistoryListItem, OrderHistoryDetailItem } from '../types/order'
import { extractSearchParams } from '../../utils'

export const selectDeliveryTimes = (state: any) => path([
  'api',
  'lists',
  'Segments',
  selectTheme(state),
  'dlv_times',
], state)

export const selectAllergens = (state: AppStateType): { id: number, text: string }[] | undefined => path([
  'api',
  'lists',
  'Alergens'
], state)

export const selectDeliveryDates = (state: any) => path([
  'api',
  'lists',
  'Segments',
  selectTheme(state),
  'dlv_dates',
], state)

export const selectDeliveryAddress = (state: any) => path([
  'api',
  'login',
  'customerRegister',
  path(['api', 'env', 'data', 'id_customer'], state) || '',
  'addressRegister',
  path(['api', 'env', 'data', 'id_dlv_addr'], state) || '',
  'addr',
], state)

export const selectMinOrder = (state: any) => path([
  'api',
  'login',
  'customerRegister',
  path(['api', 'env', 'data', 'id_customer'], state) || '',
  'addressRegister',
  path(['api', 'env', 'data', 'id_dlv_addr'], state) || '',
  'min_order',
], state)

export const selectSignedUser = (state: AppStateType) => path([
  'api',
  'login',
  'customerRegister',
  path(['api', 'env', 'data', 'id_customer'], state) || '',
], state)

export const selectCustomerData = (state: AppStateType): (CustomerType | undefined) => path([
  'api',
  'customer',
  path(['id'], selectSignedUser(state) || '') || '',
  'customer',
], state)

export const selectPriceType = (state: AppStateType) => path([
  'api',
  'lists',
  'Segments',
  path(['seg'], selectSignedUser(state)) || '',
  'baseprice',
], state)

export const selectCanProceedToOrder = (state: any): boolean => {
  const minOrder = selectMinOrder(state)
  // @ts-ignore
  return !isEmpty(path(['api', 'order', 'cart', 'dlv_times'], state) || []) && reduce(and, true, map(time => (path(['minordtot'], time)||0) >= minOrder, path(['api', 'order', 'cart', 'dlv_times'], state) || []))
}

export const selectCreatedOrder = path([
  'api',
  'order-created',
])

export const selectSubcategoryIdFromCatId = (id: number) => (state: AppStateType): undefined | number => {
  // @ts-ignore
  const cat_id = head(path([
    'api',
    'categories',
    'categoryPaths',
    id,
  ], state) || [])

  // Is it the main category?
  if (path(['api', 'categories', 'register', cat_id || ''], state)) {
    return id
  }
  return cat_id
}

export const selectCategoryByUrl = (state: any, url: string | undefined) => path([
  'api',
  'categories',
  'register',
  path(['api', 'categories', 'byUrl', url || ''], state) || '',
], state)

export const selectSubcategory = (state: any, id: number | undefined) => path([
  'api',
  'categories',
  'subRegister',
  id || 0,
], state)

export const selectDefaultCategoryUrl = (state: any) => path([
  // @ts-ignore
  'api', 'categories', 'register', last(path(['api', 'categories', 'list'], state) || []) || '', 'url'
], state)

export const selectCart = (state: AppStateType): (OrderType | undefined) => path(['api', 'order', 'cart'], state)

export const selectLanguage = (state: any) => ({
  chosen:
    path(['api', 'env', 'data', 'lang'], state) // Environment language
    || path(['fields', 'values', 'choose', 'lang', 'label'], state) // Chosen in Environment form
    || path(['api', 'login', 'lang'], state) // Login language
    || path(['ui', 'languageAfterLogout'], state) // Language after logout
    || path(['lang'], extractSearchParams(window.location.search))
    || path(['api', 'languages', 'default', 'lang'], state), // Default language for segment
  options: path(['api', 'languages', 'list'], state),
})

export const selectAnonym = (state: any) => Boolean(path(['api', 'login', 'withoutRegistration'], state) && !path(['api', 'login', 'catalog'], state))
export const selectCatalog = path(['api', 'login', 'catalog'])

export const selectOrderHistoryList = (state: AppStateType): undefined | OrderHistoryListItem[] => {
  return path(['orderHistory', 'data'], state.api)
}

export const selectOrderHistoryListLength = (state: AppStateType): undefined | number => {
  return path(['orderHistory', 'all_rows_count'], state.api)
}

export const selectOrderHistoryItem = (id: string) => (state: AppStateType): undefined | OrderHistoryDetailItem => {
  return path(['orderHistoryItem', id, 'data'], state.api)
}

export const selectContentDataList = (category: string) => (state: AppStateType): undefined | ContentDataType[] => {
  return path(['content', category, 'list', 'data'], state.api)
}

export const selectContentDataListMoreDataEnabled = (category: string) => (state: AppStateType): boolean => {
  return Boolean(path(['content', category, 'list', 'noMoreData'], state.api))
}

type ResponseType = {
  code: number,
  type: string,
}
export const selectFetchResponse = (method: string, formPath: (string | number)[]) =>
  (state: AppStateType): undefined | ResponseType => {
    return path([method, ...formPath], state.fetch.status)
  }
